<template>
    <section
        class="o-flexible-module"
        :class="[{ 'o-flexible-module--flush': componentData.settings.spacing == 'flush' }]">
        <div v-if="componentData.settings.show_component_title" class="grid-container">
            <div class="grid-x grid-margin-x">
                <div class="small-12 cell">
                    <div class="utl-heading-beta">Offset Image Pods</div>
                </div>
            </div>
        </div>

        <div
            class="c-offset-image-pods"
            :class="[
                `t-${componentData.settings.theme}`,
                { 'has-no-padding': componentData.settings.remove_padding == true },
            ]">
            <div class="grid-container">
                <div class="grid-x grid-margin-x align-middle">
                    <div class="small-12 cell">
                        <template v-if="title">
                            <h2
                                v-if="componentData.settings.heading_level == 'h2'"
                                class="c-offset-image-pods__title wow animate__animated animate__fadeIn"
                                v-html="title"></h2>

                            <h3
                                v-else-if="componentData.settings.heading_level == 'h3'"
                                class="c-offset-image-pods__title wow animate__animated animate__fadeIn"
                                v-html="title"></h3>

                            <div
                                v-else
                                class="c-offset-image-pods__title wow animate__animated animate__fadeIn"
                                v-html="title"></div>
                        </template>

                        <div
                            v-if="content"
                            class="c-offset-image-pods__content s-wp-cms wow animate__animated animate__fadeIn"
                            v-html="content"></div>

                        <div class="c-offset-image-pods__pods">
                            <template v-for="(pod, index) in pods" :key="index">
                                <NuxtLink
                                    v-if="pod.link.url"
                                    class="c-offset-image-pods__pod"
                                    :to="pod.link.url"
                                    :title="pod.title">
                                    <div
                                        class="c-offset-image-pods__pod-wrapper wow animate__animated animate__fadeInUp">
                                        <div
                                            class="c-offset-image-pods__pod-media"
                                            :class="[{ 'is-desktop': pod.mobile_image }]"
                                            v-if="pod?.image?.attachments?.['large']"
                                            v-html="pod.image.attachments['large']"></div>

                                        <div
                                            v-if="pod.mobile_image && pod?.mobile_image?.attachments?.['large']"
                                            class="c-offset-image-pods__pod-media is-mobile"
                                            v-html="pod.mobile_image.attachments['large']"></div>

                                        <div
                                            class="c-offset-image-pods__pod-title wow animate__animated animate__fadeIn"
                                            v-html="pod.title"></div>
                                    </div>
                                </NuxtLink>

                                <div v-else class="c-offset-image-pods__pod">
                                    <div
                                        class="c-offset-image-pods__pod-wrapper wow animate__animated animate__fadeInUp">
                                        <div
                                            class="c-offset-image-pods__pod-media"
                                            :class="[{ 'is-desktop': pod.mobile_image }]"
                                            v-html="pod.image.attachments['large']"></div>

                                        <div
                                            v-if="pod.mobile_image && pod?.mobile_image?.attachments?.['large']"
                                            class="c-offset-image-pods__pod-media is-mobile"
                                            v-html="pod.mobile_image.attachments['large']"></div>

                                        <div
                                            class="c-offset-image-pods__pod-title wow animate__animated animate__fadeIn"
                                            v-html="pod.title"></div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <NuxtLink
                            noPrefetch
                            v-if="link.url"
                            :to="link.url"
                            :target="link.target"
                            class="c-offset-image-pods__link wow animate__animated animate__fadeIn">
                            <span v-html="link.title"></span>
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    const props = defineProps({
        componentData: {
            type: Object,
            required: true,
        },
    });

    const title = ref(null);
    const content = ref(null);
    const link = ref([]);
    const pods = ref([]);

    if (props.componentData.content_source == "global") {
        const globalData = ref(null);

        const getGlobalOffsetImagePods = async () => {
            const { data } = await useFetch(`/api/wp/components/offset-image-pods`);

            // Check if data is empty or undefined
            if (!data || !data.value || data.value.length === 0) {
                // console.log('No data found for the given route:', route.fullPath);

                throw createError({
                    statusCode: 404,
                    statusMessage: "Page Not Found",
                });
            }

            globalData.value = data.value;
        };

        await getGlobalOffsetImagePods();

        // console.log("Global Data: ", globalData.value);

        title.value = globalData.value.title;
        content.value = globalData.value.content;
        link.value = globalData.value.link;
        pods.value = globalData.value.pods;
    } else {
        title.value = props.componentData.title;
        content.value = props.componentData.content;
        link.value = props.componentData.link;
        pods.value = props.componentData.pods;
    }

    // console.log("Data: ", props.componentData);
</script>
